.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



/* Define the keyframe animation */
@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Apply the animation to the element */
.fadeInOut {
  opacity: 0; /* Initial state */
  animation: fadeInOut 2s ease-in-out infinite;
}

.fadeIn{
  opacity: 0; /* Initial state */
  animation: fadeIn 1s ease-in-out forwards;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.spinn {
  animation: App-logo-spin infinite 5s linear;
}

.spinnn {
  animation: App-logo-spin infinite 5s linear reverse;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }

  100% {
    background-position: 1000px 0;
  }
}

.shimmer {
  background: linear-gradient(to right,
      #f6f7f8 0%,
      #e0e0e0 20%,
      #f6f7f8 40%,
      #f6f7f8 100%);
  background-size: 1000px 100%;
  animation: shimmer 2s infinite linear;
  display: inline-block;
  height: 100px;
  /* Adjust height as needed */
}